var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{staticClass:"pt-0 pb-2",attrs:{"append-icon":"mdi-magnify","label":"Artikelsuche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"article-selection-table custom-scrollbar",attrs:{"height":_vm.height,"items":_vm.tableItems,"headers":_vm.tableHeaders,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"footer-props":{
      showCurrentPage: true,
      'items-per-page-text': 'Artikel pro Seite:',
      'items-per-page-options': [10, 25, 50, -1]
    },"options":{
      sortBy: ['articleName'],
      sortDesc: [false],
      multiSort: false,
    },"dense":"","fixed-header":""},scopedSlots:_vm._u([{key:"item.addArticle",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","tile":"","color":"primary","disabled":_vm.disableAddArticle(item)},on:{"click":function($event){return _vm.addArticleToCart(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}},{key:"item.deviceType",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.deviceTypeText(item.deviceType))+" ")]}},{key:"item.accessory",fn:function(ref){
    var item = ref.item;
return [(item.requiredAccessories.length > 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.requiredAccessories.length)+" Artikel ")])]}}],null,true)},_vm._l((item.requiredAccessories),function(accessory){return _c('li',{key:accessory.id,staticClass:"mb-0"},[_vm._v(" "+_vm._s(accessory.articleName)+" ")])}),0):_vm._e()]}},(_vm.outOfStockCheckbox)?{key:"footer.prepend",fn:function(){return [_c('v-checkbox',{attrs:{"label":"Out of Stock anzeigen"},model:{value:(_vm.showOutOfStock),callback:function ($$v) {_vm.showOutOfStock=$$v},expression:"showOutOfStock"}})]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }