<template>
  <v-dialog
    v-if="dialog"
    :value="dialog"
    max-width="1200"
    @input="close"
  >
    <v-card max-width="1200">
      <v-toolbar flat>
        <v-toolbar-title>
          Artikel: {{ selectedItem.articleName }} [{{
            selectedItem.articleNumber
          }}]
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <br />
      <v-container>
        <v-row
          no-gutters
          class="pl-2"
        >
          <v-col order="first">
            <v-row>
              <v-col cols="4">
                <h4 class="label">
                  Artikeltyp:
                </h4>
              </v-col>
              <v-slide-group show-arrows>
                <v-col cols="6">
                  <v-slide-item
                    v-for="n in listDeviceType"
                    :key="n.value"
                  >
                    <v-btn
                      class="mr-3"
                      rounded
                      :color="deviceType === n.text ? 'primary' : undefined"
                      @click="selectDeviceType(n.text)"
                    >
                      {{ n.value }}
                    </v-btn>
                  </v-slide-item>
                </v-col>
              </v-slide-group>
            </v-row>
            <v-row>
              <v-col cols="4">
                <h5 class="label">
                  Mindestbestand:
                </h5>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="minStock"
                  type="number"
                  min="0"
                  :error-messages="inputErrors.minStock"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="deviceType === 'ROUTER' || deviceType === 'ONT'">
              <v-col cols="4">
                <h5 class="label">
                  Technologie:
                </h5>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="pastTechnologyDto"
                  :items="technologyItems"
                  :rules="[v => !!v || 'Es muss eine Technologie ausgewählt werden']"
                  required
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="deviceType === 'ROUTER' || deviceType === 'ONT'">
              <v-col cols="4">
                <h5 class="label">
                  Priorität:
                </h5>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="priority"
                  type="number"
                  min="0"
                  :error-messages="inputErrors.priority"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <div v-if="deviceType === 'ROUTER' || deviceType === 'ONT'">
              <v-row>
                <v-col cols="4">
                  <h5 class="label">
                    Bandbreite (MBit/s):
                  </h5>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="minRange"
                    type="number"
                    min="0"
                    :error-messages="minRangeError"
                    label="Min"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="maxRange"
                    type="number"
                    min="0"
                    :error-messages="maxRangeError"
                    label="Max"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="deviceType === 'ROUTER'">
                <v-col cols="4">
                  <span>
                    <h5 class="label">ACS Fähigkeit:</h5>
                  </span>
                </v-col>
                <v-col cols="6">
                  <toggle-button
                    v-model="acs"
                    :labels="{ checked: 'Ja', unchecked: 'Nein' }"
                    :color="{ checked: '#0CF0B8', unchecked: '#777777' }"
                    :height="25"
                    :width="60"
                  />
                </v-col>
              </v-row>
              <v-row v-if="deviceType === 'ROUTER'">
                <v-col cols="4">
                  <span>
                    <h5 class="label">Premium Router:</h5>
                  </span>
                </v-col>
                <v-col cols="6">
                  <toggle-button
                    v-model="premiumRouter"
                    :labels="{ checked: 'Ja', unchecked: 'Nein' }"
                    :color="{ checked: '#0CF0B8', unchecked: '#777777' }"
                    :height="25"
                    :width="60"
                  />
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-card>
            <v-card-title>Zugeordnete Zubehör</v-card-title>

            <v-card-text>
              <v-icon color="#26C6DA">
                mdi-information
              </v-icon>
              Diese Zubehör Artikel werden immer mit versendet, wenn der Artikel
              <br />
              {{ selectedItem.articleName }} durch die Versandregeln ausgewählt
              wird.
            </v-card-text>
            <v-col order="last">
              <v-row>
                <v-col cols="14">
                  <v-combobox
                    v-model="select"
                    :items="filteredAccessories"
                    :item-text="
                      (item) => ` ${item.articleName} / ${item.articleNumber} `
                    "
                    label="Neuen Artikel hinzufügen"
                    chips
                    outlined
                    @change="addItem(select)"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="14">
                  <v-data-table
                    :headers="headers"
                    :items="selectedAccessories"
                    class="elevation-1"
                    :items-per-page="10"
                    :footer-props="{
                      showFirstLastPage: true,
                      showCurrentPage: true,
                      'items-per-page-text': 'Zeilen pro Seite:',
                      'items-per-page-options': [10, 20, 30, 40, -1]
                    }"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon
                        size="small"
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <template v-slot:[`item.articleNumber`]="{ item }">
                      {{ item.articleNumber }}

                      <v-badge
                        v-if="
                          select && select.articleNumber === item.articleNumber
                        "
                        class="badge"
                        content="neuer Artikel"
                        color="green"
                      >
                      </v-badge>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          :disabled="saving"
          :loading="saving"
          @click="close"
        >
          <v-icon>mdi-cancel</v-icon>
          Abbrechen
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          :disabled="saveDisabled || saving"
          :loading="saving"
          @click="save"
        >
          <v-icon>mdi-check</v-icon>
          speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TimeUtility from '@/util/TimeUtility.js';
import { HTTP } from '@/main/httpClient.js';
import _ from 'lodash';
import NotificationObject from '@/main/NotificationObject.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
export default {
  name: 'EditArticleRow',
  props: {
    filteredArticleslist: {
      type: Array,
      required: true
    },
    selectedItem: {
      type: Object,
      required: true
    },
    listOptionalArticles: { type: Array, required: true },
    dialog: {
      type: Boolean,
      required: true,
      default: false
    },
    availableAccessories: {
      type: Array,
      required: true
    },
    technologies: {
      type: Array,
      required: true
    }
  },
  data: () => {
    return {
      saving: false,
      deviceType: '',
      selectedAccessories: [],
      premiumRouter: false,
      acs: false,
      select: '',
      optionsTable: [],
      editedIndex: -1,
      editedItem: {
        articleName: '',
        articleNumber: ''
      },
      defaultItem: {
        articleName: '',
        articleNumber: ''
      },
      listDeviceType: [
        { text: 'ONT', value: 'ONT' },
        { text: 'ROUTER', value: 'Router' },
        { text: 'ACCESSORY', value: 'Zubehör' }
      ],
      minRange: 1,
      maxRange: 3000,
      minStock: 0,
      priority: 0,
      technology: null,
      newArticleNumber: '',
      inputErrors: {
        minStock: '',
        priority: ''
      },
      headers: [
        {
          text: 'Artikelname',
          value: 'articleName',
          sortable: false,
          width: '60%'
        },
        {
          text: 'Artikelnummer',
          value: 'articleNumber',
          sortable: false,
          width: '20%'
        },
        {
          text: 'Bestand',
          value: 'currentStock',
          sortable: false,
          width: '10%'
        },
        { text: 'löschen', value: 'actions', sortable: false, width: '10%' }
      ]
    };
  },
  computed: {
    filteredAccessories: function () {
      let accessoryList = this.availableAccessories;
      this.selectedAccessories.forEach((accessory) => {
        accessoryList = accessoryList.filter(
          (item) => item.articleNumber !== accessory.articleNumber
        );
      });
      accessoryList = accessoryList.filter(
        (item) => item.articleNumber !== this.selectedItem.articleNumber
      );
      return accessoryList;
    },
    saveDisabled: function () {
      let disable = false;
      Object.keys(this.inputErrors).forEach((key) => {
        if (this.inputErrors[key] !== '') {
          disable = true;
        }
      });
      disable =
        disable ||
        this.minRangeError !== '' ||
        this.maxRangeError !== '' ||
        !this.pastTechnologyDto;
      return disable;
    },
    technologyItems: function () {
      return this.technologies.map((t) => {
        return {
          text: `${t.type} - ${t.name}`.toUpperCase(),
          value: t
        };
      });
    },
    minRangeError: function () {
      if (!this.minRange || isNaN(this.minRange)) {
        return 'Ungültige Eingabe';
      } else if (this.maxRange && this.minRange > this.maxRange) {
        return 'Die minimale Bandbreite ist größer als die maximale Bandbreite!';
      } else if (this.minRange <= 0) {
        return 'Wert muss größer als 0 sein';
      } else {
        return '';
      }
    },
    maxRangeError: function () {
      if (!this.maxRange || isNaN(this.maxRange)) {
        return 'Ungültige Eingabe';
      } else if (this.minRange && this.minRange > this.maxRange) {
        return 'Die minimale Bandbreite ist größer als die maximale Bandbreite!';
      } else if (this.maxRange <= 0) {
        return 'Wert muss größer als 0 sein';
      } else {
        return '';
      }
    }
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.priority = this.selectedItem.priority;
        this.minStock = this.selectedItem.minimumStock;
        this.select = undefined;
        this.pastTechnologyDto = null;
        this.deviceType = this.selectedItem.deviceType;
        if (this.selectedItem.associatedRouter) {
          this.minRange =
            this.selectedItem.associatedRouter?.minimalBandwidth / 1000;
          this.maxRange =
            this.selectedItem.associatedRouter?.maximalBandwidth / 1000;
          this.acs = this.selectedItem.associatedRouter?.acsCapable;
          this.premiumRouter =
            this.selectedItem.associatedRouter?.premiumDevice;
          this.pastTechnologyDto =
            this.selectedItem.associatedRouter.pastTechnologyDto;
        }
        if (this.selectedItem.associatedOnt) {
          this.minRange =
            this.selectedItem.associatedOnt?.minimalBandwidth / 1000;
          this.maxRange =
            this.selectedItem.associatedOnt?.maximalBandwidth / 1000;
        }
        this.selectedAccessories = this.filteredArticleslist.slice();
      }
    },
    priority(newValue, oldValue) {
      if (
        (!newValue && newValue !== 0) ||
        isNaN(newValue) ||
        newValue > 100 ||
        newValue < 0
      ) {
        this.inputErrors.priority = 'Ungültige Eingabe';
      } else {
        this.inputErrors.priority = '';
      }
    },
    minStock(newValue) {
      if ((!newValue && newValue !== 0) || isNaN(newValue) || newValue < 0) {
        this.inputErrors.minStock = 'Ungültige Eingabe';
      } else {
        this.inputErrors.minStock = '';
      }
    },
    filteredArticleslist(newValue) {
      this.selectedAccessories = newValue.slice();
    }
  },
  methods: {
    close() {
      this.$emit('dialogClosed', false);
    },
    addItem(item) {
      if (item) {
        this.selectedAccessories.push(item);
      }
    },
    selectDeviceType(newDeviceType) {
      this.deviceType = newDeviceType;
    },
    arrayRemove(arr, value) {
      return arr.filter(function (ele) {
        return ele.articleNumber !== value.articleNumber;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.selectedAccessories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.selectedAccessories.splice(this.editedIndex, 1);
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.optionsTable.push({
        articleName: item.articleName,
        articleNumber: item.articleNumber
      });
    },
    save() {
      this.saving = true;
      const changedItem = _.cloneDeep(this.selectedItem);
      changedItem.priority = parseInt(this.priority);
      changedItem.minimumStock = parseInt(this.minStock);
      changedItem.deviceType = this.deviceType;
      if (changedItem.deviceType === 'ONT') {
        changedItem.associatedOnt = {
          ...changedItem.associatedOnt,
          maximalBandwidth: parseInt(this.maxRange) * 1000,
          minimalBandwidth: parseInt(this.minRange) * 1000
        };
      }
      if (changedItem.deviceType === 'ROUTER') {
        changedItem.associatedRouter = {
          ...changedItem.associatedRouter,
          acsCapable: this.acs ? this.acs : false,
          premiumDevice: this.premiumRouter ? this.premiumRouter : false,
          maximalBandwidth: parseInt(this.maxRange) * 1000,
          minimalBandwidth: parseInt(this.minRange) * 1000,
          pastTechnologyDto: this.pastTechnologyDto
        };
      }
      changedItem.requiredAccessories = this.selectedAccessories;
      HTTP.patch('/seloca/modifySelocaArticle', changedItem)
        .then((resp) => {
          this.$emit('articleEdit', changedItem);
          this.close();
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'success',
              `Der Artikel '${changedItem.articleName}' wurde erfolgreich geändert.`
            )
          );
        })
        .catch((e) => {
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                e.response.data,
                'Bei der Änderung des Artikels ist ein Fehler aufgetreten.'
              )
            )
          );
        })
        .finally(() => {
          this.saving = false;
        });
    },
    formatDate(date) {
      return date ? TimeUtility.formatLocalDate(date) : '-';
    }
  }
};
</script>
<style>
.padding {
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.title {
  font-size: var(--goe-fontSize-extraLarge);
  color: var(--goe-fontColor-dark);
  text-transform: uppercase;
  font-weight: var(--goe-fontWeight-light);
  padding: var(--goe-spacing-3);
  background: var(--goe-background-highlight);
}
.background {
  background: #00bcd4;
}
.badge {
  padding-right: 10px;
}
.label {
  font-weight: normal;
}
</style>
