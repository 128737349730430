<!-- eslint-disable vue/html-indent -->
<template>
  <div class="wrapper">
    <div
      v-if="!hideFilter"
      class="input-row-auto"
      style="max-width: 850px; grid-template-columns: 40% 40% 20%;"
    >
      <v-text-field
        ref="filterCustomerId"
        v-model="filterCustomerId"
        label="Kundennummer"
        type="number"
        clearable
        autofocus
      />
      <v-text-field
        ref="filterContractId"
        v-model="filterContractId"
        label="Vertragsnummer"
        type="number"
        clearable
      />
    </div>
    <v-data-table
      class="elevation-1"
      :headers="tableHeaders"
      :items="tableItems"
      :options.sync="tableOptions"
      :loading="tableLoading"
      :server-items-length="tableTotalItems"
      :items-per-page="15"
      fixed-header
      dense
      disable-sort
      disable-filtering
      :footer-props="{ showFirstLastPage: true, itemsPerPageOptions: [ 5, 15, 30, 50 ] }"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              :class="`state-circle state-${item.processState}`"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>
            {{ item.processState }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.articlesToShip`]="{ item }">
        <span
          v-if="item.selocaOrderInformationDto
            && item.selocaOrderInformationDto.articlesToShip
            && item.selocaOrderInformationDto.articlesToShip.length > 0"
          class="cursor-pointer"
          @click.prevent="dialogOrder = item"
        >{{ item.selocaOrderInformationDto.articlesToShip.length }} Artikel</span>
      </template>
      <template v-slot:[`item.tracking`]="{ item }">
        <span
          v-if="item.supplier && item.trackingId"
          class="cursor-pointer"
        >
          <a
            :href="getTrackingUrl(item.supplier, item.trackingId)"
            style="color: var(--v-secondary-base) !important;"
            target="_blank"
          >{{ item.supplier }} {{ item.trackingId }}</a>
        </span>
      </template>
      <template v-slot:[`item.lastActivityName`]="{ item }">
        <span
          v-if="item.lastActivityName"
          class="cursor-pointer"
          @click.prevent="dialogBpmn = true; dialogBpmnOrder = item"
        >{{ item.lastActivityName }}</span>
        <span
          v-else
          class="cursor-pointer"
          @click.prevent="dialogBpmn = true; dialogBpmnOrder = item"
        >{{ item.lastActivityId }}</span>
        <span class="secondary--text"> - {{ formatDate(item.startTime) }}</span>
        <span
          v-if="item.endTime"
          class="secondary--text"
        > - {{ formatDate(item.endTime) }}</span>
      </template>
      <template v-slot:[`item.sentManually`]="{ item }">
        <v-simple-checkbox
          :value="item.sentManually"
          disabled
        />
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogBpmn"
      fullscreen
      scrollable
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          BPMN Diagramm
        </v-card-title>
        <v-card-text>
          <vue-bpmn
            v-if="dialogBpmnOrder !== null"
            ref="bpmnComponent"
            :url="bpmnDiagramUrl"
            @shown="bpmnShown"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogBpmn = false; dialogBpmnOrder = null"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="dialogOrder"
      v-click-outside="() => dialogOrder = null"
      :value="dialogOrder !== null"
      width="650"
      scrollable
    >
      <v-card :loading="dialogLoading">
        <v-card-title
          v-if="dialogLoading"
          class="text-h6 grey lighten-2"
        >
          Wird geladen...
        </v-card-title>
        <v-card-title
          v-else
          class="text-h5 grey lighten-2"
        >
          Seloca Bestellung {{ dialogOrder.selocaOrderInformationDto.orderNumber }}
        </v-card-title>
        <v-card-text v-if="!dialogLoading">
          <template v-for="(article, index) in dialogOrder.selocaOrderInformationDto.articlesToShip">
            <div
              :key="index"
              class="pt-6"
            >
              <span>
                <v-icon
                  v-if="selocaArticleInformation[article.id].deviceType === 'ROUTER'"
                  title="Router"
                  class="pr-1"
                >mdi-router-network</v-icon>
                <v-icon
                  v-if="selocaArticleInformation[article.id].deviceType === 'ONT'"
                  title="ONT"
                  class="pr-1"
                >mdi-router</v-icon>
                <v-icon
                  v-if="selocaArticleInformation[article.id].deviceType === 'ACCESSORY'"
                  title="Zubehör"
                  class="pr-1"
                >mdi-note</v-icon>
                {{ article.quantity }}x {{ selocaArticleInformation[article.id].articleName }}
              </span>
              <template v-if="selocaArticleInformation[article.id].requiredAccessories && selocaArticleInformation[article.id].requiredAccessories.length > 0">
                <br />
                <span class="pl-8">Inklusive Zubehör:</span>
                <li
                  v-for="(accessory) in selocaArticleInformation[article.id].requiredAccessories"
                  :key="accessory.id"
                  class="pl-8"
                  style="margin-bottom: 0px;"
                >
                  {{ accessory.articleName }}
                </li>
              </template>
            </div>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="!dialogLoading">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogOrder = null"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueBpmn from 'vue-bpmn';
import { HTTP } from '@/main/httpClient.js';
import TimeUtility from '@/util/TimeUtility.js';
import { getTrackingUrl } from '@/util/SelocaUtil.js';
import NotificationObject from '@/main/NotificationObject.js';
import { objectToEncodedQueryParamString } from '@/util/ObjectUtil.js';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';

export default {
  name: 'OrderHistory',
  components: {
    DisplayContainer,
    VueBpmn
  },
  props: {
    customerId: { type: String, required: false, default: null },
    contractId: { type: String, required: false, default: null },
    hideFilter: { type: Boolean, required: false, default: false }
  },
  data: () => ({
    bpmnComponent: null,
    filterCustomerId: null,
    filterContractId: null,
    debounceTimer: null,
    dialogBpmn: false,
    dialogBpmnOrder: null,
    dialogOrder: null,
    dialogLoading: false,
    selocaArticleInformation: {},
    tableItems: [],
    tableTotalItems: 0,
    tableLoading: false,
    tableOptions: {},
    tableHeaders: [],
    publicPath: process.env.BASE_URL
  }),
  computed: {
    bpmnDiagramUrl() {
      const { protocol, host } = document.location;
      return `${protocol}//${host}/Kundenanschaltung_Seloca_Versand.bpmn`;
    }
  },
  watch: {
    tableOptions: {
      handler() {
        this.fetchHistory();
      }
    },
    filterCustomerId() {
      this.fetchEntriesDebounced();
    },
    filterContractId() {
      this.fetchEntriesDebounced();
    },
    dialogOrder(order) {
      if (!order) return;
      this.dialogLoading = true;
      const {
        selocaOrderInformationDto: { articlesToShip }
      } = order;
      const promiseArray = [];
      articlesToShip.forEach((article) => {
        if (
          !Object.prototype.hasOwnProperty.call(
            this.selocaArticleInformation,
            article.id
          )
        ) {
          promiseArray.push(this.fetchArticle(article.id));
        }
      });
      Promise.all(promiseArray)
        .then((articles) => {
          articles.forEach((article) => {
            this.selocaArticleInformation[article.id] = article;
          });
        })
        .catch((err) => {
          this.showToast(err.message);
          this.dialogLoading = false;
          this.dialogOrder = null;
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    }
  },
  created() {
    this.tableHeaders.push({
      text: 'Status',
      align: 'start',
      value: 'status',
      width: '90px'
    });

    if (this.customerId) {
      this.filterCustomerId = this.customerId;
    } else {
      this.tableHeaders.push({
        text: 'Kundennummer',
        align: 'start',
        value: 'customerId'
      });
    }

    if (this.contractId) {
      this.filterContractId = this.contractId;
    } else {
      this.tableHeaders.push({
        text: 'Vertragsnummer',
        align: 'start',
        value: 'contractId'
      });
    }

    this.tableHeaders.push(
      ...[
        {
          text: 'Artikel',
          align: 'start',
          value: 'articlesToShip'
        },
        {
          text: 'Tracking',
          align: 'start',
          value: 'tracking'
        },
        {
          text: 'Letzte Prozess Aktivität',
          align: 'start',
          value: 'lastActivityName'
        },
        {
          text: 'Manuell beauftragt',
          align: 'end',
          value: 'sentManually',
          width: '220px'
        }
      ]
    );
  },
  methods: {
    fetchEntriesDebounced(timeout = 500) {
      if (this.tableLoading) return;
      clearTimeout(this.debounceTimer);

      this.debounceTimer = setTimeout(() => {
        this.tableOptions.page = 1;
        this.fetchHistory();
      }, timeout);
    },
    fetchHistory() {
      this.tableLoading = true;

      const payload = {
        pageSize: this.tableOptions.itemsPerPage,
        page: this.tableOptions.page - 1
      };

      if (this.filterContractId) {
        payload.contractId = this.filterContractId;
      }
      if (this.filterCustomerId) {
        payload.customerId = this.filterCustomerId;
      }

      const queryString = objectToEncodedQueryParamString(payload);

      HTTP.get('/seloca/allSelocaSentDevices?' + queryString)
        .then(({ data, status }) => {
          if (status !== 200) {
            this.showToast([
              'Fehler beim abrufen der Versandhistorie',
              `Status Code: ${status}`
            ]);
            return;
          }
          this.tableItems = data.selocaSentDevicesList;
          this.tableTotalItems = data.total;
        })
        .catch((e) => {
          this.showToast([
            'Fehler beim abrufen der Versandhistorie',
            e.message
          ]);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    fetchArticle(articleId) {
      return HTTP.get(`/seloca/getSelocaArticle/${articleId}`).then(
        ({ data, status }) => {
          if (status !== 200) return;
          data.deviceType = 'ACCESSORY';
          if (data.associatedRouter) data.deviceType = 'ROUTER';
          if (data.associatedOnt) data.deviceType = 'ROUTER';
          return data;
        }
      );
    },
    getTrackingUrl(supplier, trackingId) {
      return getTrackingUrl(supplier, trackingId);
    },
    bpmnShown() {
      const { bpmnComponent } = this.$refs;
      this.bpmnAddOverlay(bpmnComponent.bpmnViewer);
    },
    bpmnAddOverlay(viewer) {
      var canvas = viewer.get('canvas');
      canvas.addMarker(this.dialogBpmnOrder.lastActivityId, 'bpmn-highlight');
    },
    showToast(message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    },
    formatDate(dateString) {
      if (!dateString) return dateString;
      return TimeUtility.formatDateTimeWithoutUhr(dateString);
    }
  }
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.input-row-auto {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: var(--goe-spacing-1);
}

.state-circle {
  width: 21px;
  height: 21px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #646464;
}

.state-ACTIVE,
.state-COMPLETED {
  background-color: #80ed99;
  box-shadow: 0 0 4px 1px #80ed99;
}

.state-EXTERNALLY_TERMINATED,
.state-INTERNALLY_TERMINATED {
  background-color: #d2e622;
  box-shadow: 0 0 4px 1px #d2e622;
}
</style>
<style>
.bpmn-highlight:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: limegreen !important;
}
</style>
