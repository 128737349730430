<template>
  <div>
    <v-card>
      <EditArticleRow
        :selectedItem="selectedItem"
        :filteredArticleslist="filteredArticleslist"
        :technologies="pastTechnologies"
        :dialog="dialog"
        :listOptionalArticles="listOptionalArticles"
        :availableAccessories="availableAccessories"
        @dialogClosed="dialog = $event"
        @articleEdit="editSelectedArticle($event)"
      />
      <div>
        <v-data-table
          :headers="headers"
          :items="articlesData"
          item-key="articleName"
          :loading="loadingData"
          :items-per-page="100"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            'items-per-page-text': 'Zeilen pro Seite:',
            'items-per-page-options': [50, 100, 150, -1]
          }"
          :options="{
            sortBy: ['articleName'],
            sortDesc: [false],
            multiSort: false,
          }"
          class="elevation-1"
        >
          <template v-slot:[`item.deviceType`]="{ item }">
            <span>{{
              item.deviceType === 'ROUTER'
                ? 'Router'
                : item.deviceType === 'ACCESSORY'
                  ? 'Zubehör'
                  : item.deviceType
            }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:[`item.updated`]="{ item }">
            {{ formatDate(item.updated) }}
          </template>
          <template v-slot:[`item.requiredAccessories`]="{ item }">
            <v-tooltip
              :disabled="item.requiredAccessories.length <= 0"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.requiredAccessories.length }}</span>
              </template>
              <span>
                <ul
                  v-for="article in item.requiredAccessories"
                  :key="article.id"
                >
                  <li>
                    {{ article.articleNumber }} / {{ article.articleName }}
                  </li>
                </ul>
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.priority`]="{ item }">
            {{ item.deviceType !== 'ACCESSORY' ? item.priority : '-' }}
          </template>
          <template v-slot:[`item.orderedNumber`]="{ item }">
            <span>
              {{ item.orderedNumber ? item.orderedNumber : 0 }}
            </span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.orderedNumber > item.currentStock"
                  small
                  color="var(--goe-color-warning-dark)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-alert-circle
                </v-icon>
              </template>
              <span>Versendung überschreitet den aktuellen Bestand</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.minimumStock`]="{ item }">
            {{ item.minimumStock }}
          </template>
          <template v-slot:[`item.currentStock`]="{ item }">
            <span>{{ item.currentStock }}</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.currentStock < item.minimumStock"
                  small
                  color="var(--goe-color-warning-dark)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-alert-circle
                </v-icon>
              </template>
              <span>
                Der aktuelle Warenbestand liegt unter dem konfigurierten
                Mindestbestand!
              </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog
      :value="editArticleLoading"
      max-width="800"
    >
      <v-card
        max-width="800"
        loading
      >
        <v-toolbar flat>
          <v-toolbar-title>Daten werden geladen</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import TimeUtility from '@/util/TimeUtility.js';
import EditArticleRow from './EditArticleRow.vue';
export default {
  name: 'ArtikelUbersichtTable',
  components: { EditArticleRow },

  data: () => {
    return {
      loadingData: false,
      selectedItem: {},
      dialog: false,
      articlesData: [],
      listOptionalArticles: [],
      filteredArticleslist: [],
      availableAccessories: [],
      pastTechnologies: [],
      editArticleLoading: false,
      headers: [
        {
          text: 'Artikelbezeichnung',
          align: 'start',
          value: 'articleName',
          width: '20%'
        },
        { text: 'Artikeltyp', value: 'deviceType', width: '7%' },
        { text: 'Artikelnummer', value: 'articleNumber', width: '7%' },
        { text: 'Aktualisierungsdatum', value: 'updated', width: '12%' },
        { text: 'Mindestbestand', value: 'minimumStock', width: '10%' },
        { text: 'Aktueller Bestand', value: 'currentStock' },
        { text: 'Aktuell in Versendung', value: 'orderedNumber' },
        { text: 'Priorität', value: 'priority' },
        { text: 'Anzahl Zubehör', value: 'requiredAccessories' },
        { text: 'Aktionen', value: 'actions', sortable: false, width: '5%' }
      ]
    };
  },
  mounted: function () {
    this.getArticlesData();
  },
  methods: {
    async editItem(item) {
      this.editArticleLoading = true;
      if (!this.pastTechnologies || this.pastTechnologies.length <= 0) {
        this.pastTechnologies = await this.fetchPastTechnologies();
      }
      this.selectedItem = item;
      this.filteredArticleslist = item.requiredAccessories;
      this.listOptionalArticles = this.articlesData;
      if (this.filteredArticleslist.length > 0) {
        this.filteredArticleslist.forEach((selectedItem) => {
          this.listOptionalArticles = this.listOptionalArticles.filter(
            (article) =>
              article.articleName !== selectedItem.articleName &&
              article.articleNumber !== selectedItem.articleNumber
          );
        });
      } else {
        this.listOptionalArticles = this.articlesData;
      }
      this.dialog = true;
      this.editArticleLoading = false;
    },
    formatDate(date) {
      return date ? TimeUtility.formatLocalDate(date) : '-';
    },
    getArticlesData() {
      this.loadingData = true;
      HTTP.get('/seloca/allSelocaArticles')
        .then((res) => {
          this.articlesData = res.data;
          this.availableAccessories = this.articlesData.filter(
            (article) => article.deviceType === 'ACCESSORY'
          );
          this.availableAccessories.forEach((accessory) => {
            accessory.priority = -1;
          });
        })
        .catch((e) => {})
        .finally(() => {
          this.loadingData = false;
        });
    },
    async fetchPastTechnologies() {
      return new Promise((resolve, reject) => {
        HTTP.get('/seloca/allPastTechnologies').then(({ data, status }) => {
          if (status !== 200) {
            return reject(new Error('Fehler beim laden der Technologien'));
          }

          return resolve(data);
        });
      });
    },
    editSelectedArticle(editedArticle) {
      Object.assign(this.selectedItem, editedArticle);
    }
  }
};
</script>
<style lang="scss" scoped>
.padding {
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.button {
  text-align: right;
  width: 10%;
  position: absolute;
  right: 0;
  padding-top: 20px;
  padding-right: 40px;
}
</style>
