<template>
  <v-dialog
    v-model="orderDialog"
    max-width="1400px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="color"
        class="ma-2 white--text"
        :loading="loadingData"
        v-bind="attrs"
        v-on="on"
      >
        Versandübersicht
        <v-icon
          dark
          right
        >
          mdi-package-variant
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">
          Seloca Versandübersicht
          <span v-if="customerId"> | Kundennummer {{ customerId }}</span>
          <span v-if="contractId"> | Vertragsnummer {{ contractId }}</span>
        </span>
      </v-card-title>
      <v-card-text>
        <order-history
          v-if="!loadingData && orderDialog"
          :customerId="customerId"
          :contractId="contractId"
          :hideFilter="hideFilter"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="orderDialog = false"
        >
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OrderHistory from '@/components/seloca/OrderHistory.vue';

export default {
  name: 'OrderHistoryDialog',
  components: { OrderHistory },
  props: {
    customerId: { type: Number, default: null },
    contractId: { type: Number, default: null },
    hideFilter: { type: Boolean, default: false },
    loadingData: { type: Boolean, default: false },
    color: { type: String, default: 'green' }
  },
  data: () => ({
    orderDialog: false
  })
};
</script>

<style>
</style>
