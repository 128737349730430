var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('EditArticleRow',{attrs:{"selectedItem":_vm.selectedItem,"filteredArticleslist":_vm.filteredArticleslist,"technologies":_vm.pastTechnologies,"dialog":_vm.dialog,"listOptionalArticles":_vm.listOptionalArticles,"availableAccessories":_vm.availableAccessories},on:{"dialogClosed":function($event){_vm.dialog = $event},"articleEdit":function($event){return _vm.editSelectedArticle($event)}}}),_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.articlesData,"item-key":"articleName","loading":_vm.loadingData,"items-per-page":100,"footer-props":{
          showFirstLastPage: true,
          showCurrentPage: true,
          'items-per-page-text': 'Zeilen pro Seite:',
          'items-per-page-options': [50, 100, 150, -1]
        },"options":{
          sortBy: ['articleName'],
          sortDesc: [false],
          multiSort: false,
        }},scopedSlots:_vm._u([{key:"item.deviceType",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.deviceType === 'ROUTER' ? 'Router' : item.deviceType === 'ACCESSORY' ? 'Zubehör' : item.deviceType))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.updated",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updated))+" ")]}},{key:"item.requiredAccessories",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"disabled":item.requiredAccessories.length <= 0,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.requiredAccessories.length))])]}}],null,true)},[_c('span',_vm._l((item.requiredAccessories),function(article){return _c('ul',{key:article.id},[_c('li',[_vm._v(" "+_vm._s(article.articleNumber)+" / "+_vm._s(article.articleName)+" ")])])}),0)])]}},{key:"item.priority",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.deviceType !== 'ACCESSORY' ? item.priority : '-')+" ")]}},{key:"item.orderedNumber",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.orderedNumber ? item.orderedNumber : 0)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(item.orderedNumber > item.currentStock)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"var(--goe-color-warning-dark)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Versendung überschreitet den aktuellen Bestand")])])]}},{key:"item.minimumStock",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.minimumStock)+" ")]}},{key:"item.currentStock",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.currentStock))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(item.currentStock < item.minimumStock)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"var(--goe-color-warning-dark)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" Der aktuelle Warenbestand liegt unter dem konfigurierten Mindestbestand! ")])])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"value":_vm.editArticleLoading,"max-width":"800"}},[_c('v-card',{attrs:{"max-width":"800","loading":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Daten werden geladen")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }