<template>
  <v-dialog
    v-model="orderDialog"
    class="manually-order-dialog custom-scrollbar"
    max-width="950px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="green"
        class="ma-2 white--text"
        :loading="loadingData || loading"
        v-bind="attrs"
        v-on="on"
      >
        Manueller Seloca Versand
        <v-icon
          dark
          right
        >
          mdi-package-variant
        </v-icon>
      </v-btn>
    </template>
    <v-card :loading="orderDialogLoading">
      <v-card-title>
        <span
          v-if="step === 1"
          class="text-h5"
        >Manueller Seloca Versand</span>
        <span
          v-if="step === 2"
          class="text-h5"
        >Versand bestätigen</span>
        <v-spacer></v-spacer>
        <v-icon
          v-if="stockIsNotSufficient"
          color="warning"
          dark
        >
          mdi-alert
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <span v-if="orderDialogLoading">Daten werden geladen...</span>
        <template v-else>
          <v-autocomplete
            v-show="step === 1"
            v-model="selectedContractId"
            :items="contractAutoComplete"
            class="mt-4"
            label="Vertrag auswählen"
          ></v-autocomplete>
          <div v-if="selectedContractId">
            <v-container>
              <display-container
                label="Empfängerdaten"
                isSubcomponent
              >
                <div class="input-row mt-2">
                  <data-display label="Name">
                    {{ selectedContract.deliveryAddress.fullName }}
                  </data-display>
                  <data-display label="Adresse">
                    {{ selectedContract.deliveryAddress.compactAddress }}
                  </data-display>
                </div>
              </display-container>
            </v-container>
            <v-container>
              <display-container
                v-show="step === 1"
                label="Seloca Artikelliste"
                isSubcomponent
              >
                <div class="mt-4">
                  <article-selection-table
                    ref="articleSelection"
                    :items="[...selocaRouter, ...selocaOnt, ...selocaAccessory]"
                    :items-per-page="-1"
                    :limit-router="limitRouter"
                    :limit-ont="limitOnt"
                    height="20vh"
                    dense
                    group-by="deviceType"
                    group-desc
                    @updateCart="updateCart"
                  />
                </div>
              </display-container>
            </v-container>
            <v-card elevation="1">
              <v-card-title>Bestellliste</v-card-title>
              <v-card-text>
                <span
                  v-show="cart.length === 0"
                  class="h6"
                >Es befinden sich keine Artikel auf der Bestellliste</span>
                <v-container class="cart-list-container custom-scrollbar">
                  <template v-for="({ id, amount, article }, index) in cart">
                    <v-divider
                      v-if="index > 0"
                      :key="`${id}d`"
                      class="col-12"
                    />
                    <v-row
                      :key="id"
                      :class="[...index > 0 ? ['pt-3'] : []]"
                      no-gutters
                    >
                      <v-btn
                        v-show="isArticleLimited(article)"
                        title="Artikel entfernen"
                        class="mx-6"
                        color="red"
                        large
                        tile
                        icon
                        @click.prevent="updateCartArticleAmount(id, 0)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-select
                        v-show="!isArticleLimited(article) && amount < 10"
                        :items="[{ text: '0 (löschen)', value: 0, }, { divider: true }, 1, 2, 3, 4, 5, 6, 7, 8, 9, { divider: true }, { text: '10+', value: 10 }]"
                        :value="amount"
                        class="col-1 ml-6 select-amount-list"
                        label="Menge"
                        dense
                        @change="updateCartArticleAmount(id, $event)"
                      ></v-select>
                      <v-text-field
                        v-show="!isArticleLimited(article) && amount >= 10"
                        :value="amount"
                        class="col-1 ml-6"
                        label="Menge"
                        type="number"
                        dense
                        min="1"
                        max="99"
                        @change="updateCartArticleAmount(id, $event)"
                      ></v-text-field>
                      <span class="mx-8">
                        <v-tooltip
                          v-if="article.currentStock < amount"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="warning"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-alert
                            </v-icon>
                          </template>
                          <span>Achtung, der Lagerbestand für diesen Artikel niedriger als die gewünschte Menge!</span>
                        </v-tooltip>
                        {{ deviceTypeText(article.deviceType) }} - {{ article.articleName }}
                        <template v-if="article.requiredAccessories && article.requiredAccessories.length > 0">
                          <br />
                          <v-tooltip
                            v-if="article.requiredAccessories.filter(i => i.currentStock < amount).length > 0"
                            top
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="warning"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-alert
                              </v-icon>
                            </template>
                            <span>Achtung, der Lagerbestand für die Zubehör Artikel ist niedriger als die gewünschte Menge!</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                :class="article.requiredAccessories.filter(i => i.currentStock < amount).length === 0 ? 'pl-4' : ''"
                                v-bind="attrs"
                                v-on="on"
                              >
                                Inklusive {{ article.requiredAccessories.length }} Zubehör Artikel
                              </span>
                            </template>
                            <li
                              v-for="accessory in article.requiredAccessories"
                              :key="accessory.id"
                              class="mb-0"
                            >
                              {{ accessory.articleName }}
                            </li>
                          </v-tooltip>
                        </template>
                      </span>
                    </v-row>
                  </template>
                </v-container>
              </v-card-text>
            </v-card>
            <template v-if="stockIsNotSufficient && step === 2">
              <v-alert
                dense
                prominent
                type="warning"
                class="mt-4"
                color="warning"
              >
                <h3 class="text-h6  ">
                  Es befinden sich Artikel mit unzureichendem Bestand auf der Bestellliste.
                </h3>
                <v-checkbox
                  v-model="confirmLowStock"
                  dense
                  label="Bestätigen zum fortfahren"
                ></v-checkbox>
              </v-alert>
            </template>
          </div>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          text
          color="primary"
          @click="orderDialog = false; step = 1;"
        >
          Abbrechen
        </v-btn>
        <v-btn
          v-if="step === 2"
          color="primary"
          text
          @click="step = 1"
        >
          Zurück
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="step === 1"
          :disabled="cart.length === 0"
          color="warning"
          text
          @click="step = 2; step2Locked = true"
        >
          Weiter
        </v-btn>
        <v-btn
          v-if="step === 2"
          :disabled="step2Locked || loading || (stockIsNotSufficient && !confirmLowStock)"
          :loading="loading"
          color="warning"
          text
          @click="sendManuallyOrder"
        >
          {{ cart.length }} Artikel
          <template v-if="accessoriesLength > 0">
            + {{ accessoriesLength }} Zubehör
          </template> versenden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ArticleSelectionTable from '@/components/seloca/ArticleSelectionTable.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import NotificationObject from '@/main/NotificationObject.js';

import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'ManuallyOrderDialog',
  components: { DisplayContainer, DataDisplay, ArticleSelectionTable },
  props: {
    loadingData: { type: Boolean, required: true },
    contractData: { type: Object, required: true },
    customerId: { type: Number, required: true },
    limitRouter: { type: Boolean, default: true },
    limitOnt: { type: Boolean, default: true }
  },
  data: () => ({
    orderDialog: false,
    orderDialogLoading: false,
    hideOutOfStock: false,
    selectedContractId: null,
    selocaArticles: null,
    cart: [],
    step: 1,
    step2Locked: false,
    loading: false,
    confirmLowStock: false
  }),
  computed: {
    stockIsNotSufficient: function () {
      return this.cart.reduce((isNotSufficient, { amount, article }) => {
        if (isNotSufficient) return true;
        return (
          isNotSufficient ||
          !this.checkArticleStockIsSufficient(amount, article)
        );
      }, false);
    },
    contractAutoComplete: function () {
      if (!this.contractData) return [];
      const contracts = [];
      Object.keys(this.contractData).forEach((contractId) => {
        const contract = this.contractData[contractId];
        if (!contract || !contract.internetMainProduct) return;
        contracts.push({
          text: `${contract.contractId} - ${contract.internetMainProduct?.name}`,
          value: contract.contractId
        });
      });

      return contracts;
    },
    selectedContract: function () {
      if (!this.selectedContractId) return;
      return this.contractData[this.selectedContractId];
    },
    selocaRouter: function () {
      return this.filterArticleList('Router', 'ROUTER');
    },
    selocaOnt: function () {
      return this.filterArticleList('ONT', 'ONT');
    },
    selocaAccessory: function () {
      return this.filterArticleList('Zubehör', 'ACCESSORY');
    },
    cartHasRouter: function () {
      return (
        this.cart.filter((i) => i.article.deviceType === 'ROUTER').length > 0
      );
    },
    cartHasOnt: function () {
      return this.cart.filter((i) => i.article.deviceType === 'ONT').length > 0;
    },
    accessoriesLength: function () {
      const length = this.cart.reduce((sum, { article, amount }) => {
        let tempSum = sum;

        if (this.articleHasAccessories(article)) {
          tempSum += article.requiredAccessories.length * amount;
        }

        return tempSum;
      }, 0);
      return length;
    }
  },
  watch: {
    contractAutoComplete() {
      if (this.contractAutoComplete.length === 1) {
        // select first entry if only one contract exists
        this.selectedContractId = this.contractAutoComplete[0].value;
      }
    },
    async selectedContractId(contractId) {
      if (!contractId) return;
      this.orderDialogLoading = true;

      const promises = [];

      if (!this.selocaArticles) {
        promises.push(
          HTTP.get('/seloca/allSelocaArticles').then(({ data }) => {
            this.selocaArticles = data;
          })
        );
      }

      if (this.selectedContract.deliveryAddress === undefined) {
        promises.push(
          HTTP.get(
            `/customer/addressByType/${this.selectedContractId}/DELIVERY`
          ).then(({ data: address }) => {
            address.compactAddress = `${address.zipCode ?? ''} ${
              address.city ?? ''
            }, ${address.street ?? ''} ${address.houseNumber ?? ''}${
              address.houseNumberSupplement ?? ''
            }`;
            address.fullName = `${address.firstName ?? ''} ${
              address.lastName ?? ''
            }`;
            this.selectedContract.deliveryAddress = address;
          })
        );
      }

      Promise.all(promises).finally(() => {
        this.orderDialogLoading = false;
      });
    },
    step2Locked(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.step2Locked = false;
        }, 2000);
      }
    }
  },
  created() {},
  methods: {
    isArticleLimited(article) {
      switch (article.deviceType) {
        case 'ROUTER': {
          return this.limitRouter;
        }
        case 'ONT': {
          return this.limitOnt;
        }
        default:
          return false;
      }
    },
    checkArticleStockIsSufficient(amount, article) {
      let isSufficient = true;
      if (
        article.requiredAccessories &&
        article.requiredAccessories.length > 0
      ) {
        article.requiredAccessories.forEach((accessory) => {
          const item = this.findArticleById(accessory.id);
          isSufficient &= this.checkArticleStockIsSufficient(
            amount,
            item ?? accessory
          );
        });
      }
      return isSufficient && amount <= article.currentStock;
    },
    findArticleById(articleId) {
      return this.selocaArticles.find((item) => item.id === articleId);
    },
    updateCart(cart) {
      this.cart = cart;
    },
    updateCartArticleAmount(articleId, newAmount) {
      if (isNaN(parseInt(newAmount))) return;
      this.$refs.articleSelection.updateCartArticleAmount(
        articleId,
        parseInt(newAmount)
      );
    },
    articleHasAccessories(article) {
      return (
        article &&
        article.requiredAccessories &&
        article.requiredAccessories.length > 0
      );
    },
    filterArticleList(name, type) {
      if (!this.selocaArticles) return [];

      return this.selocaArticles.filter((article) => {
        return article.deviceType === type;
      });
    },
    deviceTypeText(deviceType) {
      switch (deviceType) {
        case 'ROUTER':
          return 'Router';
        case 'ACCESSORY':
          return 'Zubehör';
        default:
          return deviceType;
      }
    },
    sendManuallyOrder() {
      this.loading = true;
      const order = {
        contractId: this.selectedContractId,
        customerId: this.customerId,
        articlesToShipDto: {
          articlesToShip: this.cart.reduce((cart, { id, amount, article }) => {
            cart.push({
              id: id,
              quantity: amount
            });

            if (this.articleHasAccessories(article)) {
              article.requiredAccessories.forEach((accessory) => {
                cart.push({
                  id: accessory.id,
                  quantity: amount
                });
              });
            }

            return cart;
          }, [])
        }
      };
      HTTP.post('/seloca/sendDeviceManually', order)
        .then(() => {
          this.cart.forEach((article) => {
            this.updateCartArticleAmount(article.id, 0);
          });
          this.step = 1;
          this.confirmLowStock = false;
          this.showToast(
            'Der Versand wurde erfolgreich bei Seloca beauftragt',
            'success'
          );
        })
        .catch((err) => {
          this.showToast(err.message);
        })
        .finally(() => {
          this.loading = false;
          this.orderDialog = false;
        });
    },
    showToast(message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    }
  }
};
</script>
<style scoped>
.cart-list-container {
  overflow: auto;
  height: auto;
  max-height: 20vh;
}
</style>
<style>
.select-amount-list .v-list--dense .v-list-item {
  min-height: 0px !important;
}
</style>
