<template>
  <div>
    <v-text-field
      v-model="search"
      class="pt-0 pb-2"
      append-icon="mdi-magnify"
      label="Artikelsuche"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :height="height"
      :items="tableItems"
      :headers="tableHeaders"
      :items-per-page="itemsPerPage"
      :search="search"
      :footer-props="{
        showCurrentPage: true,
        'items-per-page-text': 'Artikel pro Seite:',
        'items-per-page-options': [10, 25, 50, -1]
      }"
      :options="{
        sortBy: ['articleName'],
        sortDesc: [false],
        multiSort: false,
      }"
      class="article-selection-table custom-scrollbar"
      dense
      fixed-header
    >
      <template v-slot:[`item.addArticle`]="{ item }">
        <v-btn
          icon
          tile
          color="primary"
          :disabled="disableAddArticle(item)"
          @click="addArticleToCart(item)"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.deviceType`]="{ item }">
        {{ deviceTypeText(item.deviceType) }}
      </template>
      <template v-slot:[`item.accessory`]="{ item }">
        <v-tooltip
          v-if="item.requiredAccessories.length > 0"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              style="cursor: pointer"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.requiredAccessories.length }} Artikel
            </span>
          </template>
          <li
            v-for="accessory in item.requiredAccessories"
            :key="accessory.id"
            class="mb-0"
          >
            {{ accessory.articleName }}
          </li>
        </v-tooltip>
      </template>
      <template
        v-if="outOfStockCheckbox"
        v-slot:[`footer.prepend`]
      >
        <v-checkbox
          v-model="showOutOfStock"
          label="Out of Stock anzeigen"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'SelocaArticleSelectionTable',
  components: {},
  props: {
    items: { type: Array, required: true },
    itemsPerPage: { type: Number, default: -1 },
    height: { type: String, default: 'auto' },
    outOfStockCheckbox: { type: Boolean, default: true },
    showOutOfStockItems: { type: Boolean, default: false },
    limitRouter: { type: Boolean, default: true },
    limitOnt: { type: Boolean, default: true }
  },
  data: () => ({
    cart: [],
    tableHeaders: [
      {
        value: 'addArticle',
        sortable: false,
        width: '25px'
      },
      {
        text: 'Artikel',
        value: 'articleName'
      },
      {
        text: 'Typ',
        value: 'deviceType'
      },
      {
        text: 'Lagerbestand',
        value: 'currentStock'
      },
      {
        text: 'Zubehör',
        value: 'accessory'
      }
    ],
    search: '',
    showOutOfStock: false
  }),
  computed: {
    tableItems() {
      return this.items.filter((item) => {
        return this.search || this.showOutOfStock || item.currentStock > 0;
      });
    },
    cartHasRouter: function () {
      return (
        this.cart.filter((i) => i.article.deviceType === 'ROUTER').length > 0
      );
    }
  },
  watch: {
    showOutOfStockItems() {
      this.showOutOfStock = this.showOutOfStockItems;
    }
  },
  mounted() {},
  created() {
    this.showOutOfStock = this.showOutOfStockItems;
  },
  methods: {
    addArticleToCart(article, setAmount = 0) {
      let cartItemIndex = this.cart.findIndex((a) => a.id === article.id);

      if (cartItemIndex < 0) {
        cartItemIndex = this.cart.length;
        this.cart.push({
          id: article.id,
          article,
          amount: 0
        });
      }

      const newAmount =
        setAmount > 0 ? setAmount : this.cart[cartItemIndex].amount + 1;

      this.cart[cartItemIndex].amount = newAmount;
      this.$emit('updateCart', this.cart);
    },
    disableAddArticle(article) {
      switch (article.deviceType) {
        case 'ROUTER':
          return (
            this.limitRouter &&
            this.cart.filter((i) => i.article.deviceType === 'ROUTER').length >
              0
          );
        case 'ONT':
          return (
            this.limitOnt &&
            this.cart.filter((i) => i.article.deviceType === 'ONT').length > 0
          );
        default:
          return false;
      }
    },
    removeArticleFromCart(article) {
      const cartItemIndex = this.cart.findIndex((a) => a.id === article.id);
      if (cartItemIndex < 0) return;
      this.cart.splice(cartItemIndex, 1);
      this.$emit('updateCart', this.cart);
    },
    updateCartArticleAmount(articleId, newAmount) {
      const article = this.items.find((item) => item.id === articleId);
      if (!article) return;
      if (newAmount > 0) return this.addArticleToCart(article, newAmount);
      this.removeArticleFromCart(article);
    },
    deviceTypeText(deviceType) {
      switch (deviceType) {
        case 'ROUTER':
          return 'Router';
        case 'ACCESSORY':
          return 'Zubehör';
        default:
          return deviceType;
      }
    }
  }
};
</script>
<style lang="css">
.article-selection-table > .v-data-table__wrapper tbody tr td:first-child {
  padding: 0px;
}

.article-selection-table > .v-data-table__wrapper tbody tr td:nth-child(2) {
  padding-left: 5px;
}
</style>
